import { Calendar } from '@fullcalendar/core';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';



function renderCalendar() {
  var calendarEl = document.getElementById('calendar-timeline');

  if (!calendarEl) return false;

  let allStock = []
  $.ajaxSetup({async: false});
  $.get(calendarEl.dataset.allStockUrl, function(data) {
    allStock = data
  })
  let calendar = new Calendar(calendarEl, {
    schedulerLicenseKey: '0879881934-fcs-1703097861',
    plugins: [ resourceTimelinePlugin, interactionPlugin ],
    initialView: 'resourceTimelineWeek',
    firstDay: 1,
    timeZone: 'UTC',
    resourceOrder: 'order',
    views: {
      resourceTimelineDay: {
        slotLabelFormat: [
          { hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: true,
            hour12: false }
        ],
      },
      resourceTimelineWeek: {
        slotLabelFormat: [
          { month: 'short', day: 'numeric', weekday: 'short' },
          { hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: true,
            hour12: false }
        ],
      }
    },
    resourceLabelDidMount: function(info) {
      info.el.title = info.resource.title;

      if (info.resource.extendedProps.book_url) {

        var questionMark = document.createElement('a');
        questionMark.href = info.resource.extendedProps.book_url;
        questionMark.className = 'btn btn-light btn-sm book-btn ml-2';
        questionMark.id= 'book-btn-' + info.resource.id;
        questionMark.innerHTML = '<i class="bi bi-bag-plus"></i>';

        info.el.querySelector('.fc-datagrid-cell-main')
          .appendChild(questionMark);
      }
      //info.el.style.cursor = 'pointer';
    },
    headerToolbar: {
      left: 'today prev,next',
      center: 'title',
      right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth'
    },
    eventDidMount: function(info) {
      info.el.title = info.event.title;
      info.el.style.cursor = 'pointer';
    },
    resources: allStock,
    events: calendarEl.dataset.allEventsUrl,
    resourcesInitiallyExpanded: false,
    eventClick: function(info) {
      window.location.href = info.event.url;
    },
    dateClick: function(info) {
      const id = info.resource._resource.parentId || info.resource.id;
      const date = info.date;
      const button = document.getElementById('book-btn-' + id);
      let url = button.href + '&start_date=' + formatDate(date) + '&end_date=' + formatDate(date);
      openBookingModal(url);
    }
  });

  calendar.render();
}

function formatDate(date) {
  return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
}

function openBookingModal(url) {
  $.get(url, function(data) {
    $('#active-booking-time-form').html(data.html);
    $('#active-booking-time-form').modal('show');
    window.toggleDateInputs();
  });
}

document.addEventListener('DOMContentLoaded', function () {
  renderCalendar();

  $(document).on('click', '.book-btn', function(event) {
    event.stopPropagation();
    event.preventDefault();
    openBookingModal($(this).attr('href'));

  });
});
